import { useCallback, useState } from 'react';
import Input from '../../../../components/Input/Input';
import s from './Login.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import ButtonQuery from '../../../../query/ButtonQuery';
import { useAdminLogin } from '../../../../api/auth';
import { useQueryReset } from '../../../../utils/query';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  setLoggedIn,
  setUserData,
} from '../../../../redux/slices/userAdminSlice';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [failedLogin, setFailedLogin] = useState(false);
  const setQueryReset = useQueryReset(failedLogin);
  const adminLogin = useAdminLogin();
  const dispatch = useAppDispatch();

  const handleError = useCallback(
    (axiosError: any, reset: () => void) => {
      const error = axiosError.response.data.error.message;
      setQueryReset(reset);

      if (error.toLowerCase().includes('invalid')) {
        setFailedLogin(true);
      } else {
        console.error(axiosError.response.data.error.message);
      }
    },
    [setQueryReset]
  );

  const handleLogin = (axiosResponse: any) => {
    const data = axiosResponse.data;

    if (data.statusCode === 200) {
      dispatch(
        setUserData({
          jwt: data.data.jwt,
          airtableApiKey:
            'patJDIPDMdZT2k01B.6a18711d2e6ed9c8d780cbbd6705cf73e115ab1f55b307a37326d27bbfa3965c',
          // airtableApiKey: data.data.airtableApiKey,
        })
      );
      dispatch(setLoggedIn(true));
    }
  };

  return (
    <div className={s.container}>
      <h2>Logowanie do konta koordynatora</h2>

      <Input
        keyfilter='email'
        errorMessage={failedLogin ? 'Nieprawidłowe hasło/email' : ''}
        value={email}
        setValue={(stateAction) => {
          if (failedLogin) {
            setFailedLogin(false);
          }

          setEmail(stateAction);
        }}
        placeholder='Adres email'
      />

      <Input
        value={password}
        errorMessage={failedLogin ? 'Nieprawidłowe hasło/email' : ''}
        setValue={(stateAction) => {
          if (failedLogin) {
            setFailedLogin(false);
          }

          setPassword(stateAction);
        }}
        placeholder='Hasło'
        password
      />

      <ButtonQuery
        style={{ width: '212px', height: '36px' }}
        disabled={failedLogin}
        mutation={adminLogin}
        mutationParam={{ email, password }}
        onMutationError={handleError}
        className={`${button.button} ${button.login}`}
        icon={<CaretCircleRightIcon />}
        onMutationSuccess={handleLogin}
      >
        <p className='button-5'>Zaloguj się</p>
      </ButtonQuery>
    </div>
  );
};

export default Login;
